<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-alert v-if="message" type="error">{{message}}</v-alert>
    <v-form>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <validation-provider :rules="{required:true,min:5,max:18,alpha_dash:true}" v-slot="{ errors }">
              <v-text-field v-model="user.username" label="Login"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }">
              <v-text-field v-model="user.password" label="Password" type="password"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" md="12">
          <v-btn :disabled="invalid" @click="handleLogin" block color="primary">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Login</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>
<script>
  import User from '../../models/user';
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {max, min, required, alpha_dash} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  extend('min', {
    ...min,
    message: 'Minimum length is 5 characters'
  });
  extend('max', {
    ...max,
    message: 'Maximum length is 18 characters'
  });
  extend('alpha_dash', {
    ...alpha_dash,
    message: 'The field may contain alpha-numeric characters, dashes and underscores'
  });

  export default {
    name: 'Login',
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        user: new User('', ''),
        loading: false,
        message: '',
      };
    },
    methods: {
      handleLogin() {
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/pages/user');
            },
            error => {
              this.message =
                (error.response && error.response.data) ||
                error.message || error.toString();
              if (this.message === 'Request failed with status code 401') {
                this.message = 'Login or password invalid!'
              }
              if (this.message.message === 'Bad credentials') {
                this.message = 'Login or password invalid!'
              }
            }
          );
        }
      },
    }
  };
</script>
<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
