<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
        <v-alert v-if="message" :type="successful ? 'success':'error'">{{message}}</v-alert>
    <v-form>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">

            <validation-provider :rules="{required:true,min:5,max:18,alpha_dash:true}" v-slot="{ errors }">
              <v-text-field v-model="user.username" label="Login"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>

            <validation-provider :rules="{required:true,email:true}" v-slot="{ errors }">
              <v-text-field v-model="user.email" label="Email"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>

            <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }">
              <v-text-field v-model="user.password" label="Password" type="password"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>

            <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }" vid="confirmation">
              <v-text-field v-model="confirmation" label="Confirm password" type="password"></v-text-field>
              <span class="red--text font-weight-light">{{ errors[0] }}</span>
            </validation-provider>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col cols="12" md="12">
          <v-btn :disabled="invalid" @click="handleRegister" block color="primary">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>Signup</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>
<script>
  import User from '../../models/user';
  import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
  import {email, max, min, required, alpha_dash, confirmed} from 'vee-validate/dist/rules';

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  extend('min', {
    ...min,
    message: 'Minimum length is 5 characters'
  });
  extend('max', {
    ...max,
    message: 'Maximum length is 18 characters'
  });
  extend('alpha_dash', {
    ...alpha_dash,
    message: 'The field may contain alpha-numeric characters, dashes and underscores'
  });
  extend('email', {
    ...email,
    message: 'Only valid e-mail address allowed'
  });
  extend('confirmed', {
    ...confirmed,
    message: 'Password does not match'
  });

  export default {
    name: 'Register',
    components: {ValidationProvider, ValidationObserver},
    data() {
      return {
        confirmation: '',
        loading: false,
        user: new User('', '', ''),
        submitted: false,
        successful: false,
        message: ''
      };
    },
    methods: {
      handleRegister() {
        this.message = '';
        this.submitted = true;
        this.$store.dispatch('auth/register', this.user).then(
          data => {
            this.message = data.message;
            this.successful = true;
          },
          error => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            this.successful = false;
          }
        );
      },
    }
  };
</script>
<style scoped>
  .red--text {
    font-size: 12px;
  }
</style>
