<template>
  <v-img :src="require(`@/assets/UI-images/bg_img.jpg`)">
    <v-app-bar elevation="0" color="transparent">
      <v-toolbar-title style="color: rgba(0, 0, 0, 0.2); font-weight: 600">TRANSDATA Cloud</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-card class="mx-auto" max-width="300" elevation="1">
      <v-img max-height="230" class="hover-img"
             :src="require(`@/assets/UI-images/login_background2.jpg`)"
             gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.7)">
        <v-card-title class="hover-img-title">TRANSDATA</v-card-title>
        <v-card-title class="hover-img-title2">Cloud</v-card-title>
      </v-img>

      <v-tabs grow>
        <v-tab>
          <v-icon left> mdi-login</v-icon>
          Login
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-account-plus
          </v-icon>
          Register
        </v-tab>

        <v-tab-item>
          <Login></Login>
        </v-tab-item>
        <v-tab-item>
          <Register></Register>
        </v-tab-item>

      </v-tabs>
    </v-card>
  </v-img>
</template>
<script>
  import Login from "./Login";
  import Register from "./Register";
  import User from '../../models/user';

  export default {
    name: 'Auth',
    components: {Login, Register},
    data() {
      return {
        user: new User('', ''),
        loading: false,
        message: '',
      };
    },
  };
</script>
<style scoped>
  .hover-img-title {
    text-transform: uppercase;
    font-size: 2.2rem;
    justify-content: center;
    color: white;
    vertical-align: bottom;
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
  }

  .hover-img-title2 {
    font-size: 1.3rem;
    justify-content: center;
    color: white;
    vertical-align: bottom;
    position: absolute;
    top: 90%;
    transform: translateY(-50%);
    float: right;
  }
</style>
