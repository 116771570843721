var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.message)?_c('v-alert',{attrs:{"type":_vm.successful ? 'success':'error'}},[_vm._v(_vm._s(_vm.message))]):_vm._e(),_c('v-form',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"rules":{required:true,min:5,max:18,alpha_dash:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Login"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required:true,email:true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required:true,min:5,max:18}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":"password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":{required:true,min:5,max:18},"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm password","type":"password"},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}}),_c('span',{staticClass:"red--text font-weight-light"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"disabled":invalid,"block":"","color":"primary"},on:{"click":_vm.handleRegister}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"spinner-border spinner-border-sm"}),_c('span',[_vm._v("Signup")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }